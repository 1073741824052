/* eslint-disable no-unused-vars */
<template>
  <div id="allApplicants">
    <div class="card height-auto v-application v-application--is-ltr">
      <div class="card-body">
        <v-data-table
          v-can="'list-applicants'"
          :options.sync="options"
          :server-items-length="this.totalApplicants"
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :headers="headers"
          :items="applicants"
          item-key="id"
          :show-select="showSelect"
          class="elevation-1 level1 overflow-x-auto"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="6" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("All Applicants Data") }}
                </h3>
              </v-col>
              <v-col md="6" sm="12" class="text-right">
                <!-- Menus -->
                <!-- ===========================================search menu================================================ -->
                <span title="Search">
                  <v-menu
                    v-model="searchMenu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :title="$t('Search')">search</v-icon>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item class="select">
                          <v-text-field
                            v-model="search.applicant_name"
                            :label="$i18n.t('Applicant Name')"
                            solo
                          ></v-text-field>
                        </v-list-item>
                        <v-list-item>
                          <v-text-field
                            v-model="search.application_number"
                            :label="$i18n.t('Application Number')"
                            solo
                          ></v-text-field>
                        </v-list-item>
                        <v-list-item>
                          <v-text-field
                            v-model="search.nationality"
                            :label="$i18n.t('Applicant Nationality')"
                            solo
                          ></v-text-field>
                        </v-list-item>
                      </v-list>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="primary"
                          text
                          @click="searchMenu = false"
                          >{{ $t("Close") }}</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </span>

                <!-- ==================================================filters================================================== -->

                <span :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <!-- <v-icon
                        v-on="on"
                        class="mr-2"
                        @click="initializeDatePicker"
                        big
                      >
                        filter
                      </v-icon>-->
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        @click="initializeDatePicker"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-select
                          :label="$i18n.t('Applicant Grade')"
                          v-model="search.grade"
                          :items="grades"
                          multiple
                          item-text="name"
                          item-value="id"
                          solo
                        ></v-select>
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          :label="$i18n.t('Applicant Gender')"
                          v-model="search.gender"
                          :items="[
                            { text: $t('Male'), val: 'male' },
                            { text: $t('Female'), val: 'female' },
                          ]"
                          multiple
                          item-text="text"
                          item-value="val"
                          solo
                        ></v-select>
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          :label="$i18n.t('Applicant Status')"
                          v-model="search.status"
                          :items="[
                            { text: $t('New'), val: 'new' },
                            { text: $t('Approved'), val: 'approved' },
                            { text: $t('Archived'), val: 'archived' },
                          ]"
                          solo
                          multiple
                          item-text="text"
                          item-value="val"
                        ></v-select>
                      </v-list-item>

                      <v-list-item>
                        <v-text-field
                          :label="$i18n.t('Date From')"
                          id="applicantDateFrom"
                          solo
                          v-model="search.application_date_from"
                        ></v-text-field>
                      </v-list-item>
                      <v-list-item>
                        <v-text-field
                          :label="$i18n.t('Date To')"
                          solo
                          id="applicantDateTo"
                          v-model="search.application_date_to"
                        ></v-text-field>
                      </v-list-item>
                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="primary"
                          text
                          @click="filterMenu = false"
                          >{{ $t("Close") }}</v-btn
                        >
                      </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>

                <!-- delete -->
                <span v-can="'delete-applicants'" title="Delete">
                  <v-icon
                    big
                    style="color: red"
                    @click="bulkDeleteDialog"
                    :title="$t('Delete')"
                    >delete</v-icon
                  >
                </span>

                <span v-can="'change-applicants-status-approve'">
                  <v-icon
                    big
                    class="main-color"
                    @click="bulkApproveDialogOpen"
                    :title="$t('Bulk Approve')"
                    >check_circle_outline</v-icon
                  >
                </span>
                <span v-can="'accept-applicant-as-student'">
                  <v-icon
                    big
                    class="main-color"
                    @click="showBulkConvertApplicantToStudentDialog"
                    :title="$t('Bulk change applicant status')"
                    >cached</v-icon
                  >
                </span>
                <!-- ====================================export================================== -->
                <!-- <v-icon
                  v-can="'export-applicants'"
                  big
                  class="main-color"
                  @click="exportApplicants"
                  >cloud_download</v-icon
                >-->
                <a
                  v-if="exportBtnVisible"
                  @click.prevent="exportApplicants"
                  href="#"
                  :title="$t('Export File')"
                >
                  <img src="../../assets/icons/download.svg" />
                </a>
                <!-- ===========================archive===================== -->
                <v-icon
                  v-can="'change-applicants-status-archive'"
                  big
                  class="main-color"
                  @click.prevent="bulkArchive"
                  :title="$t('Archive')"
                  >archive</v-icon
                >

                <!-- ==============================print==============================  -->
                <!-- <v-icon v-can="'print-applicants'" title="Print">print</v-icon> -->
              </v-col>
            </v-row>
          </template>
          <!-- <template v-slot:top>
            <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
          </template>-->
          <!-- <template v-slot:item.application_number="{ item }">
            <router-link
              target="_blank"
              :to="'/applicants/' + item.id"
              v-if="showBtnVisible"
            >{{ item.application_number }}</router-link>
            <p v-if="!showBtnVisible">{{ item.application_number }}</p>
          </template>-->
          <template v-slot:item.application_number="{ item }">
            <v-tooltip bottom max-width="2800px">
                
              <template v-slot:activator="{ on }">
                <router-link
                  v-on="on"
                  target="_blank"
                  :to="'/applicants/' + item.id"
                  v-if="showBtnVisible"
                  >{{ item.application_number.slice(0, 15) }}</router-link
                >
                <p v-on="on" v-if="!showBtnVisible">
                  {{ item.application_number.slice(0, 15) }}
                </p>
              </template>
              <span>
                <router-link
                  target="_blank"
                  :to="'/applicants/' + item.id"
                  v-if="showBtnVisible"
                  >{{ item.application_number }}</router-link
                >
                <p v-if="!showBtnVisible">{{ item.application_number }}</p>
              </span>
            </v-tooltip>
          </template>

          <template v-slot:item.student_full_name="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.student_full_name">
                  {{ item.student_full_name.slice(0, 15) }}
                </p>
              </template>
              <span>{{ item.student_full_name }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.student_gender="{ item }">
            {{
              item.student_gender.charAt(0).toUpperCase() +
              item.student_gender.slice(1)
            }}
          </template>

          <template v-slot:item.student_birthdate="{ item }">
            <p>
              <v-icon
                v-if="item.in_range == 1"
                color="success"
                title="The student age match the date range."
                small
                >calendar_today</v-icon
              >
              <v-icon
                v-if="item.in_range == 0"
                color="error"
                title="The student age doesn't match the date range."
                small
                >calendar_today</v-icon
              >
              {{ item.student_birthdate }}
            </p>
          </template>

          <template v-slot:item.action="{ item }">
            <!-- <v-icon small class="mr-1" style="" @click="editSingle(item.id)">
                edit
            </v-icon>-->

            <!-- archive -->
            <span
              v-if="
                editBtnVisible ||
                deleteBtnVisible ||
                archiveBtnVisible ||
                approveBtnVisible ||
                showBtnVisible
              "
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="mr-2" big>more_horiz</v-icon>
                </template>

                <v-list id="applicantsMenu">
                  <v-list-item
                    v-for="(action, index) in actionMenus"
                    :key="index"
                  >
                    <v-icon
                      v-if="action.title == 'Show' && showBtnVisible"
                      :title="$t('View Profile')"
                      @click="showSingle(item.id)"
                      >{{ action.icon }}</v-icon
                    >
                    <v-icon
                      v-if="action.title == 'Edit' && editBtnVisible"
                      :title="$t('Edit')"
                      @click="editSingle(item.id)"
                      >{{ action.icon }}</v-icon
                    >
                    <v-icon
                      v-if="action.title == 'Delete' && deleteBtnVisible"
                      :title="$t('Delete')"
                      @click="showDialog(item)"
                      >{{ action.icon }}</v-icon
                    >
                    <!-- <v-icon v-if="action.title == 'Print' && printBtnVisible">{{
                      action.icon
                    }}</v-icon>-->
                    <v-icon
                      v-if="action.title == 'Archive' && archiveBtnVisible"
                      :title="$t('Archive')"
                      @click="archive(item.id)"
                      >{{ action.icon }}</v-icon
                    >
                    <v-icon
                      v-if="action.title == 'Approve' && approveBtnVisible"
                      :title="$t('Approve')"
                      @click="approve(item.id)"
                      :disabled="item.student_application_status == 'Approved'"
                      >{{ action.icon }}</v-icon
                    >
                    <v-icon
                      v-if="
                        action.title == 'Convert to student' &&
                        convertBtnVissible
                      "
                      :title="$t('Change applicant status')"
                      @click="showConvertApplicantToStudentDialog(item.id)"
                      :disabled="item.student_application_status != 'Approved'"
                      >{{ action.icon }}</v-icon
                    >
                    <!-- <v-icon
                      v-if="action.title == 'Package'"
                      :title="$t('Add Discount Package')"
                      @click="openPackageStudent(item.id)"
                      >{{ action.icon }}</v-icon
                    > -->
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
          </template>
          <template v-slot:item.student_iqama_no_expire_date="{ item }">
            <p>
              <v-icon
                :color="
                  getExpireDateColor(item.student_iqama_no_expire_date_class)
                "
                dark
                v-if="item.student_iqama_no_expire_date != null"
                :title="item.student_iqama_no_expire_date_title"
                small
                >calendar_today</v-icon
              >
              {{ item.student_iqama_no_expire_date }}
              <span
                v-if="item.student_iqama_no_expire_date == null"
                :title="item.student_iqama_no_expire_date_title"
                >N/A</span
              >
            </p>
          </template>
        </v-data-table>

        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            :disabled="disablePagination"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>

        <v-dialog
          v-model="dialog"
          persistent
          max-width="300"
          class="dialog_confirm"
        >
          <v-card>
            <v-card-title class="headline">{{
              $t("Confirmation Message")
            }}</v-card-title>

            <v-card-text>
              {{
                $t("Are you sure you want to permanently remove application no")
              }}
              {{ deletedItem.application_number }}?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                class="closebuttonvutify footer-btn bg-linkedin"
                @click="dialog = false"
                id="noBtn"
                >{{ $t("No") }}</v-btn
              >
              <v-btn
                @click="deleteItem(deletedItem)"
                class="approvebuttonvutify footer-btn bg-linkedin"
                id="yesBtn"
                >{{ $t("Yes") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="convertDialog"
          persistent
          max-width="600"
          class="dialog_confirm change-status-dialog"
        >
          <v-form v-model="valid" ref="form">
            <v-card>
              <v-card-title class="headline pt-3">{{
                $t("Change Applicant Status to:")
              }}</v-card-title>

              <v-card-text>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    :items="[
                      { title: 'Please Select', value: '' },
                      {
                        title: 'Pending Admission Exam',
                        value: 'pending_exam_result',
                      },
                      {
                        title: 'Convert To Student',
                        value: 'student',
                      },
                    ]"
                    item-text="title"
                    item-value="value"
                    v-model="changeStatus"
                    :rules="[validationRules.required]"
                    solo
                  >
                  </v-select>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  class="closebuttonvutify footer-btn bg-linkedin"
                  @click="convertDialog = false"
                  id="noBtn"
                  >{{ $t("Cancel") }}</v-btn
                >
                <v-btn
                  @click="convertApplicantToStudent()"
                  class="approvebuttonvutify footer-btn bg-linkedin"
                  id="yesBtn"
                  >{{ $t("Confirm") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog
          v-model="bulkConvertDialog"
          persistent
          max-width="600"
          class="dialog_confirm change-status-dialog"
        >
          <v-form v-model="valid" ref="form">
            <v-card>
              <v-card-title class="headline">{{
                $t("Confirmation Message")
              }}</v-card-title>

              <v-card-text>
                <v-col cols="12" sm="12" md="12">
                  <label>
                    {{ $t("Change Applicant Status") }}
                  </label>
                  <v-select
                    :items="[
                      { title: 'Please Select', value: '' },
                      {
                        title: 'Pending Admission Exam',
                        value: 'pending_exam_result',
                      },
                      {
                        title: 'Convert To Student',
                        value: 'student',
                      },
                    ]"
                    item-text="title"
                    item-value="value"
                    v-model="changeStatus"
                    :rules="[validationRules.required]"
                    solo
                  >
                  </v-select>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  class="closebuttonvutify footer-btn bg-linkedin"
                  @click="bulkConvertDialog = false"
                  id="noBtn"
                  >{{ $t("Cancel") }}</v-btn
                >
                <v-btn
                  @click="bulkConvertApplicantsToStudents()"
                  class="approvebuttonvutify footer-btn bg-linkedin"
                  id="yesBtn"
                  >{{ $t("Confirm") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </div>
    </div>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
    <!-- 
    <template v-if="admissionLoaded">
      <validateApplicant
        v-bind:data="admission"
        v-on:childToParent="onChildClick"
      ></validateApplicant>
    </template>-->
    <template v-if="admissionLoaded">
      <approveApplicant
        v-can="'change-applicants-status-approve'"
        v-bind:data="admission"
        :approveDialog="approveDialog"
        :price_plan_id="price_plan_id"
        v-on:childToParent="onChildClick"
      ></approveApplicant>
    </template>

    <!-- dialog box for delete Confirmation (later) -->
    <!-- <v-dialog
      v-model="dialogBulk"
      persistent
      max-width="300"
      class="dropdown-item dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">Confirmation Message</v-card-title>

        <v-card-text
          >Are you sure you want to delete
          {{ dialogBulkMessage }} applications?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="modal-btn-cancel" @click="dialogBulk = false" id="">
            No
          </v-btn>
          <v-btn class="modal-btn-save" @click="bulkDelete" id="">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->

    <v-dialog
      v-model="dialogBulk"
      persistent
      max-width="300"
      class="dropdown-item dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>
          {{ $t("Are you sure you want to delete") }}
          {{ dialogBulkMessage }} {{ $t("applications") }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="closebuttonvutify"
            @click="dialogBulk = false"
            id="noBtn2"
            >{{ $t("No") }}</v-btn
          >
          <v-btn class="approvebuttonvutify" @click="bulkDelete" id="yesBtn2">{{
            $t("Yes")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <bulkApproveApplicants
      :bulkApproveDialog="bulkApproveDialog"
      v-on:childToParentBulkApprove="onChildClickBulkApprove"
      :ids="ids"
    ></bulkApproveApplicants>
    <AddStudentPackageComponent
      :PackageDialog="PackageDialog"
      :student_id="applicant_id"
      v-on:childToParent="OnchildParentPackageClick"
    ></AddStudentPackageComponent>
  </div>
</template>

<script>
import { validationMixin } from "../../mixins/validationMixin";
import AddStudentPackageComponent from "../../components/modals/AddStudentPackageComponent";
import colors from "vuetify/lib/util/colors";
import axios from "axios";
import { mapGetters } from "vuex";
import ACL from "../../acl";
// import validateApplicant from "../../components/validateApplicant.vue";
import approveApplicant from "../../components/modals/approveApplicant.vue";
import bulkApproveApplicants from "../../components/modals/bulkApproveApplicants.vue";
// datepicker
import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
moment.locale("es");

import "../../assets/jquery.calendars.package-2.1.1/css/humanity.calendars.picker.css";
import "../../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.js";
import "../../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.plus.js";
import "../../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.islamic.js";

import "../../assets/jquery.calendars.package-2.1.1/js/jquery.plugin.js";
import "../../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.picker.js";

export default {
  components: {
    approveApplicant,
    bulkApproveApplicants,
    AddStudentPackageComponent,
  },
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      disablePagination: false,
      PackageDialog: false,
      applicant_id: "",
      bulkApproveDialog: false,
      showSelect: true,
      price_plan_id: "",
      admissionLoaded: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      dialogBulkMessage: "",
      dialog: false,
      convertDialog: false,
      bulkConvertDialog: false,
      convertedApplicantId: "",
      changeStatus: "",
      dialogBulk: false,
      deletedItem: [],
      admission: {
        id: "",
        application_number: "",
        student_birthdate_m: "",
        student_birthdate_h: "",
        student_place_of_birth: "",
        student_gender: "",
        student_nationality_id: "",
        student_religion: "",
        student_native_language: "",
        student_second_language: "",
        student_grade_level: "",
        student_national_id: "",
        student_passport_no: "",
        student_iqama_no: "",
        student_iqama_no_expire_date: "",
        student_curriculum: "",
        student_previous_school: "",
        contact_person_name: "",
        contact_person_email: "",
        contact_person_mobile: "",
        contact_person_relationship: "",
        contact_person_relationship_other: "",
        contact_person_communication_method: [],
        contact_person_information_certify: "1",

        en: {
          student_first_name: "",
          student_father_name: "",
          student_family_name: "",
        },
        ar: {
          student_first_name: "",
          student_father_name: "",
          student_family_name: "",
        },
      },
      nameEntries: [],
      searchNameEntries: [],
      countryEntries: [],
      searchCountryEntries: [],
      searchCountry: null,
      searchName: null,
      isLoading: false,
      model: null,
      searchAuto: null,
      count: 0,
      menu: false,
      menu2: false,
      grades: [],
      totalApplicants: 0,
      applicants: [],
      loading: true,

      options: {
        itemsPerPage: 15,
      },
      //pagination options
      totalPages: 0,
      page: 1,
      circle: true,
      length: 0,
      totalVisible: 0,
      approveDialog: false,

      // end pagination

      search: {
        application_number: "",
        applicant_name: "",
        nationality: "",
        gender: [],
        grade: [],
        application_date_from: "",
        application_date_to: "",
        status: ["new"],
        global_status: "",
      },
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Application") + " #",
          align: "left",
          sortable: false,
          value: "application_number",
          width: "150px",
        },
        {
          text: this.$i18n.t("Applicant Name"),
          value: "student_full_name",
          width: "12%",
        },
        {
          text: this.$i18n.t("Date of Birth"),
          value: "student_birthdate",
          width: "30%",
        },
        { text: this.$i18n.t("Gender"), value: "student_gender", width: "10%" },
        {
          text: this.$i18n.t("Nationality"),
          value: "student_nationality",
          width: "30%",
        },
        {
          text: this.$i18n.t("Grade Level"),
          value: "student_grade_level",
          width: "10%",
        },
        {
          text: this.$i18n.t("Iqama Exp. Date"),
          value: "student_iqama_no_expire_date",
          width: "30%",
        },
        {
          text: this.$i18n.t("Status"),
          value: "student_application_status",
          width: "10%",
        },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          width: "5%",
        },
      ],

      actionMenus: [
        { title: "Edit", icon: "edit" },
        { title: "Delete", icon: "delete" },
        { title: "Archive", icon: "archive" },
        // { title: "Print", icon: "print" },
        { title: "Approve", icon: "check_circle_outline" },
        { title: "Show", icon: "remove_red_eye" },
        { title: "Package", icon: "lock" },
        { title: "Convert to student", icon: "cached" },
      ],

      fav: true,
      searchMenu: false,
      filterMenu: false,
      message: false,
      hints: true,
      editBtnVisible: ACL.checkPermission("edit-applicants"),
      deleteBtnVisible: ACL.checkPermission("delete-applicants"),
      // printBtnVisible: ACL.checkPermission("print-applicants"),
      archiveBtnVisible: ACL.checkPermission(
        "change-applicants-status-archive"
      ),
      approveBtnVisible: ACL.checkPermission(
        "change-applicants-status-approve"
      ),
      convertBtnVissible: ACL.checkPermission("accept-applicant-as-student"),
      showBtnVisible: ACL.checkPermission("show-applicants"),
      exportBtnVisible: ACL.checkPermission("export-applicants"),
      ids: [],
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.getDataFromSearch(this.$route.query.page);
        }
        // let pageNumber = this.$router.currentRoute.params.pageNumber;
        // this.getDataFromSearch(pageNumber);
      },
    },
    page: function () {
      this.$router.push(
        {
          path: "/applicants?page=" + this.page,
        },
        () => {}
      );
    },
    options: {
      handler() {
        if (
          this.options.sortBy.length > 0 &&
          this.options.sortDesc.length > 0
        ) {
          this.getDataFromApi(this.options.page);
        }
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
        this.getDataFromSearch();
      },
      deep: true,
    },
    searchCountry(val) {
      if (val != null && val.length == 0) {
        // console.log(val.length);
        this.searchCountryEntries = [];
      } else if (val != null && val.length > 0) {
        this.searchCountryEntries = this.countryEntries;
      }
    },
    searchName(val) {
      if (val != null && val.length == 0) {
        // console.log(val.length);
        this.searchNameEntries = [];
      } else if (val != null && val.length > 0) {
        this.searchNameEntries = this.nameEntries;
      }
    },

    // dialog: {
    //   handler() {
    //     if (this.dialog) {
    //       this.autoFocusDialog();
    //     }
    //   }
    // },
    // dialogBulk: {
    //   handler() {
    //     if (this.dialogBulk) {
    //       console.log("true");
    //       this.autoFocusBulkDialog();
    //     }
    //   }
    // }
  },
  computed: {
    ...mapGetters(["getApiUrl"]),
  },
  methods: {
    OnchildParentPackageClick(val, snak = false) {
      this.PackageDialog = val;
      if (snak) {
        this.snack = true;
        this.snackColor = "green";
        this.snackText = this.$i18n.t(
          "The package has been added successfully"
        );
        this.snackTime = 2000;
      }
    },
    openPackageStudent(id) {
      this.applicant_id = id;
      this.PackageDialog = true;
    },
    bulkApproveDialogOpen() {
      this.ids = [];
      if (this.selected.length > 0) {
        let error = false;
        this.selected.forEach((applicant) => {
          if (
            applicant.in_range == false ||
            applicant.iqama_in_range == false
          ) {
            error = true;
            this.snack = true;
            this.snackColor = "error";
            this.snackText = this.$i18n.t(
              "One or more students have an exceptional case/cases"
            );
          } else {
            this.ids.push(applicant.id);
          }
        });
        if (error == false) {
          this.bulkApproveDialog = true;
        }
      } else {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = this.$i18n.t("Please select applicants first");
      }
    },
    // autoFocusDialog() {
    //   $("#noBtn").focus();
    //   setTimeout(() => {
    //     $("#noBtn").focus();
    //   }, 100);
    // },

    // autoFocusBulkDialog() {
    //   $("#noBtn2").focus();
    //   setTimeout(() => {
    //     $("#noBtn2").focus();
    //   }, 100);
    // },
    // autoFocusModals() {
    //   let _this = this;
    //   $(document).ready(function() {
    //     $(document).keydown(function(e) {
    //       let confirmDialog = _this.dialog;
    //       let bulkConfirmDialog = _this.dialogBulk;
    //       if (confirmDialog == true) {
    //         // left click
    //         if (e.keyCode == 37) {
    //           console.log("left click");
    //           $("#noBtn").focus();
    //           setTimeout(() => {
    //             $("#noBtn").focus();
    //           }, 100);
    //         }
    //         // right click
    //         if (e.keyCode == 39) {
    //           console.log("right click");
    //           $("#yesBtn").focus();
    //           setTimeout(() => {
    //             $("#yesBtn").focus();
    //           }, 100);
    //         }
    //       }
    //       if (bulkConfirmDialog == true) {
    //         // left click
    //         if (e.keyCode == 37) {
    //           console.log("left click");
    //           $("#noBtn2").focus();
    //           setTimeout(() => {
    //             $("#noBtn2").focus();
    //           }, 100);
    //         }
    //         // right click
    //         if (e.keyCode == 39) {
    //           console.log("right click");
    //           $("#yesBtn2").focus();
    //           setTimeout(() => {
    //             $("#yesBtn2").focus();
    //           }, 100);
    //         }
    //       }
    //     });
    //   });
    // },

    showSingle(id) {
      this.$router.push({
        path: `/applicants/` + id,
      });
    },
    // Triggered when `childToParent` event is emitted by the child.
    onChildClick(value, approveDialog) {
      this.admissionLoaded = value;
      this.approveDialog = approveDialog;
    },
    onChildClickBulkApprove(val) {
      this.bulkApproveDialog = val;
    },
    bulkDelete() {
      if (this.selected.length > 0) {
        axios
          .post(
            this.getApiUrl + "/applicant/deleteApplicant",
            { ids: this.selected },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.selected.forEach((id) => {
                const index = this.applicants.indexOf(id);

                this.applicants.splice(index, 1);
                this.dialogBulk = false;
              });
              this.snack = true;
              this.snackColor = "success";
              this.snackText = this.$i18n.t(
                "The selected applicants has been removed successfully"
              );
              this.totalApplicants -= this.selected.length;
              this.dialogBulkMessage = "";
              this.selected = [];

              this.getDataFromSearch(1);
            } else {
              this.snack = true;
              this.snackColor = "error";
              this.snackText = response.data.status.message;
              this.dialogBulkMessage = "";
              // this.selected = [];
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = this.$i18n.t("Please select applicants first");
      }
    },
    bulkDeleteDialog() {
      this.dialogBulkMessage = 0;
      if (this.selected.length > 0) {
        this.dialogBulk = true;
        // eslint-disable-next-line no-unused-vars
        // this.selected.forEach(applicant => {
        this.dialogBulkMessage += this.selected.length;
        // });
      } else {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = this.$i18n.t("Please select applicants first");
      }
    },
    showDialog(item) {
      this.dialog = true;
      this.deletedItem = item;
    },

    show(id) {
      this.$router.push({
        name: "ApplicantsShow",
        params: { applicant_id: id },
      });
    },
    archive(id = "") {
      axios
        .post(
          this.getApiUrl + "/applicant/updateStatus/" + id,
          { status: "archived" },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          // //console.log(response);
          if (response.data.status.error == false) {
            this.applicants.forEach((applicant) => {
              if (applicant.id == id) {
                applicant.student_application_status = "Archived";
              }
            });
          }
          //$("#status" + id).html("Archived");
        })
        .catch((err) => {
          alert(err);
        });
    },
    showBulkConvertApplicantToStudentDialog() {
      if (this.selected.length > 0) {
        this.bulkConvertDialog = true;
      } else {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = this.$i18n.t("You should select applicants first");
      }
    },
    bulkConvertApplicantsToStudents() {
      // this.convertedApplicantId
      if (this.valid) {
        if (this.changeStatus == "student") {
          axios
            .post(
              this.getApiUrl + "/applicant/bulkConvert/",
              { applicants: this.selected },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (!response.data.status.error) {
                // this.$router.push("/students");
                this.snackColor = "success";
                this.snackText =
                  "complete profile links sent to the contact persons of selected applicants";
                this.getDataFromSearch(1);
              } else {
                this.snackColor = "error";
                this.snackText = response.data.status.message;
              }
              this.snack = true;
              this.selected = [];
              this.bulkConvertDialog = false;
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/applicant/bulkPendingExamResult",
              { applicants: this.selected },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (!response.data.status.error) {
                // this.$router.push("/students");
                this.snackColor = "success";
                this.snackText =
                  "Selected applicants marked as pending admission exam.";
                this.getDataFromSearch(1);
              } else {
                this.snackColor = "error";
                this.snackText = response.data.status.message;
              }
              this.snack = true;
              this.selected = [];
              this.bulkConvertDialog = false;
            });
        }
      } else {
        this.$refs.form.validate();
      }
    },
    showConvertApplicantToStudentDialog(id) {
      this.convertedApplicantId = id;
      this.convertDialog = true;
    },
    convertApplicantToStudent() {
      if (this.valid) {
        if (this.convertedApplicantId != "") {
          if (this.changeStatus == "student") {
            axios
              .get(
                this.getApiUrl +
                  "/applicant/convert/" +
                  this.convertedApplicantId,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                if (!response.data.status.error) {
                  // this.$router.push("/students");
                  this.snackColor = "success";
                  this.snackText =
                    "The complete student profile link has been sent to the contact person";
                  this.getDataFromSearch(1);
                } else {
                  this.snackColor = "error";
                  this.snackText = response.data.status.message;
                }
                this.snack = true;
                this.convertedApplicantId = "";
                this.convertDialog = false;
              });
          } else {
            axios
              .get(
                this.getApiUrl +
                  "/applicant/pending_exam_result/" +
                  this.convertedApplicantId,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                if (!response.data.status.error) {
                  // this.$router.push("/students");
                  this.snackColor = "success";
                  this.snackText =
                    "The Applicant marked as pending admission exam.";
                  this.getDataFromSearch(1);
                } else {
                  this.snackColor = "error";
                  this.snackText = response.data.status.message;
                }
                this.snack = true;
                this.convertedApplicantId = "";
                this.convertDialog = false;
              });
          }
        }
      } else {
        this.$refs.form.validate();
      }
    },
    approve(id = "") {
      // $(".modal").modal({ backdrop: "static", keyboard: false });
      axios
        .get(this.getApiUrl + "/applicant/applicants/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.admission = response.data.data;
          // axios
          //   .get(this.getApiUrl + "/getDefaultFeePlan", {
          //     headers: {
          //       Authorization: "Bearer " + localStorage.token
          //       //the token is a variable which holds the token
          //     }
          //   })
          //   .then(response => {
          //     if (response.data.status.error == false) {
          //       //   console.log(value["id"]);
          //       this.price_plan_id = response.data.data;

          this.admissionLoaded = true;
          this.approveDialog = true;
          //   pricePlan.price_plan_id
          //     console.log(this.price_plan_id);
          //   }
          // });

          if (response.data.status.error == true) {
            this.$router.push("/applicants");
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.applicants.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      axios
        .post(
          this.getApiUrl + "/applicant/deleteApplicant/" + item.id,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            const index = this.applicants.indexOf(item);

            this.applicants.splice(index, 1);
            this.dialog = false;
            this.snack = true;
            this.snackColor = "success";
            this.snackText =
              "The selected applicant has been removed successfully";
            this.totalApplicants -= 1;
            this.getDataFromSearch(1);
          } else {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = response.data.status.message;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    bulkArchive() {
      if (this.selected.length > 0) {
        axios
          .post(
            this.getApiUrl + "/applicant/bulkUpdateStatus",
            { status: "archived", ids: this.selected },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.applicants.forEach((applicant) => {
                this.selected.forEach((id) => {
                  // //console.log(id);
                  if (applicant.id == id.id) {
                    applicant.student_application_status = "Archived";
                  }
                });
              });
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = this.$i18n.t("You should select applicants first");
      }
    },
    bulkApprove() {
      if (this.selected.length > 0) {
        axios
          .post(
            this.getApiUrl + "/applicant/updateStatus",
            { status: "approved", ids: this.selected },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.applicants.forEach((applicant) => {
                this.selected.forEach((id) => {
                  // //console.log(id.id);
                  if (applicant.id == id.id) {
                    applicant.student_application_status = "Approved";
                  }
                });
              });
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    exportApplicants() {
      // console.log(this.search);
      if (this.selected.length > 0) {
        // export selected
        axios
          .post(
            this.getApiUrl + "/applicant/applicants/export",
            { items: this.selected },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              let exportLink = response.data.data.link;
              window.open(exportLink, "_blank");
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        // export all
        axios
          .post(this.getApiUrl + "/applicant/applicants/export", this.search, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((response) => {
            if (response.data.status.error == false) {
              let exportLink = response.data.data.link;
              window.open(exportLink, "_blank");
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    getColor(in_range) {
      if (in_range == 1) return colors.green.darken1;
      // else if (in_range == 0) return "orange";
      else return colors.red.darken1;
    },
    getExpireDateColor(color) {
      if (color == "red") return "error";
      else if (color == "yellow") return "yellow";
      else if (color == "green") return "success";
      else return "";
    },
    getDataFromApi(page) {
      this.disablePagination = true;
      let sort_type = "";
      let column = "";
      if (this.options.sortBy.length > 0) {
        column = this.options.sortBy[0];
        sort_type = this.options.sortDesc[0] ? "DESC" : "ASC";
      }
      axios
        .post(
          this.getApiUrl +
            "/applicant/search?sort_type=" +
            sort_type +
            "&column=" +
            column +
            "&page=" +
            page,
          this.search,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          /* =====================pagination============================== */
          this.disablePagination = false;
          this.page = response.data.data.current_page;
          this.totalPages = response.data.data.last_page;
          this.length = this.totalPages;
          if (this.totalPages > 5) this.totalVisible = 5;
          /* ===================== end pagination============================== */

          this.applicants = response.data.data.data;
          this.loading = false;
          // eslint-disable-next-line no-unused-vars
          const { sortBy, sortDesc, page, itemsPerPage } = this.options;

          // let items = this.getDesserts();
          // eslint-disable-next-line no-unused-vars
          const total = this.applicants.length;

          if (sortBy.length === 1 && sortDesc.length === 1) {
            this.applicants = this.applicants.sort((a, b) => {
              const sortA = a[sortBy[0]];
              const sortB = b[sortBy[0]];

              if (sortDesc[0]) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            });
          }
          // if (itemsPerPage > 0) {
          //   this.applicants = this.applicants.slice(
          //     (page - 1) * itemsPerPage,
          //     page * itemsPerPage
          //   );
          // }
        });
    },
    getDataFromSearch(page) {
      this.disablePagination = true;
      let sort_type = "";
      let column = "";
      if (this.options.sortBy.length > 0) {
        column = this.options.sortBy[0];
        sort_type = this.options.sortDesc[0] ? "DESC" : "ASC";
      }
      axios
        .post(
          this.getApiUrl +
            "/applicant/search?sort_type=" +
            sort_type +
            "&column=" +
            column +
            "&page=" +
            page,
          this.search,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.disablePagination = false;
          this.page = response.data.data.current_page;
          this.totalPages = response.data.data.last_page;
          this.length = this.totalPages;
          if (this.totalPages > 5) this.totalVisible = 5;
          this.applicants = response.data.data.data;
          this.totalApplicants = response.data.data.total;
          this.loading = false;
        });
    },

    editSingle(id) {
      // console.log("single edit");
      this.$router.push("/applicants/" + id + "?editable=true");
    },
    initializeDatePicker() {
      let _this = this;

      setTimeout(() => {
        $("#applicantDateFrom").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.search.application_date_from = dateH;
            } else {
              _this.search.application_date_from = "";
            }
          },
        });

        $("#applicantDateTo").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.search.application_date_to = dateH;
            } else {
              _this.search.application_date_to = "";
            }
          },
        });
      }, 1000);
    },
  },
  mounted() {
    // this.autoFocusModals();
    if (
      !this.deleteBtnVisible &&
      !this.archiveBtnVisible &&
      !this.exportBtnVisible
    ) {
      this.showSelect = false;
    }

    if (
      !this.archiveBtnVisible &&
      !this.editBtnVisible &&
      !this.deleteBtnVisible &&
      !this.showBtnVisible &&
      // !this.printBtnVisible &&
      !this.approveBtnVisible
    ) {
      this.headers = this.headers.filter(function (row) {
        return row.value != "action";
      });
    }

    $(".v-data-footer__select").remove();

    this.page = this.$route.query.page;
    this.getDataFromSearch(this.page);
    // console.log(this.getApiUrl + "/applicant/search");
    // axios
    //   .post(this.getApiUrl + "/applicant/search", this.search, {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.token
    //     }
    //   })
    //   .then(response => {
    //     this.page = response.data.data.current_page;
    //     this.totalPages = response.data.data.last_page;
    //     this.length = this.totalPages;
    //     if (this.totalPages > 5) this.totalVisible = 5;
    //     this.applicants = response.data.data.data;
    //     this.totalApplicants = response.data.data.total;
    //     this.loading = false;
    //   });

    axios
      .get(this.getApiUrl + "/getGrades", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.grades = response.data.data.grades;
      });
    if (this.$route.query.global_status !== undefined) {
      this.search.global_status = this.$route.query.global_status;
      this.search.status = [];
    }
    // axios
    //   .get(this.getApiUrl + "/applicant/autocomplete/name", {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.token
    //     }
    //   })
    //   .then(response => {
    //     this.nameEntries = response.data.data.entries;
    //   });

    // axios
    //   .get(this.getApiUrl + "/applicant/autocomplete/country", {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.token
    //     }
    //   })
    //   .then(response => {
    //     this.countryEntries = response.data.data.entries;
    //   });
  },
};

$(document).ready(function () {
  $("#applicantDateFrom").calendarsPicker({
    maxDate: 0,
  });
  $("#applicantDateTo").calendarsPicker({
    maxDate: 0,
  });
});
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// should apply on all views
#wrapper {
  transition: all 0.4s ease 0s;
}

#wrapper.active {
  // padding-left: 200px;
}

/* start new interface */

.main-color {
  color: $main-color;
}

.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}

img {
  height: 20px !important;
  margin: 3px !important;
}

.select {
  margin-top: 20px !important;
}

/* end new interface */
</style>
